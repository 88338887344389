import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

export default function HealthAndWellness() {
  return (
    <Layout>
      <React.Fragment>
        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <div className="bordered">
            <h1 className="m-b-2">My Health & Wellness Journey</h1>
            <p className="m-b-2">
              My passion for health and wellness first began in late 2011, after several years of suffering from irritable bowel syndrome and gut infections such as E. coli and C. difficile (both after my first wisdom tooth extraction). But after adopting a plant-based diet in 2011, my stomach issues completely resolved! The solution was as simple as changing the way I ate, and I haven’t looked back. 
            </p>
            <img src="/assets/images/journey-1.1.jpg"></img>
          </div>

          <div className="bordered">
            <p>
              Flash forward to <strong>January of 2014</strong>, when my mom was diagnosed with stage four colon cancer. Desperate to cure her of this disease, I dove in headfirst to learn as much as possible about the role diet and lifestyle play in cancer prevention and treatment. I learned all about the dangers of excess sugar consumption, processed foods, and chronic stress, and worked closely with my mom to modify her diet and lifestyle. She began to eliminate refined sugars, red meats, and processed foods from her diet, juice fresh fruits and veggies daily, practice yoga, and let go of painful feelings from the past. 
            </p>
            <p>
            Thanks to these lifestyle modifications and help from the medical professionals at the Mayo Clinic and the University of Minnesota, my mom is now a seven-year cancer survivor. She continues to follow the same healthy lifestyle habits, and I couldn’t be more proud of her and all the time and effort she puts into maintaining good health. 
            </p>
          </div>
        </section>

        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <div className="bordered">
            <p>
              Now, let’s back up to <strong>October of 2015</strong> when at 27 years of age I was diagnosed with stage one ovarian mucinous adenocarcinoma, a type of ovarian cancer. Once again my family was faced with cancer and I knew I had to step up my own health and wellness game if I wanted to keep the cancer from returning in the future. You can learn more about my ovarian cancer diagnosis and surgery here. 
            </p>
            <p>
              While ovarian cancer is rare among women in their 20s and 30s, I truly believe stress contributed to it — at least partly. My mom’s cancer diagnosis had been incredibly hard on me, especially considering how close she and I have always been. I couldn’t cope with the idea of losing her — not then or ever — and the stress and trauma of her diagnosis had changed me in every way. 
            </p>
            <p>
              On top of my mom’s diagnosis, a blockage was found in my dad’s left anterior descending artery around the same time. I worried incessantly about the health of my parents and other loved ones, and the stress of all these different things was just too much. However, these events also inspired me to learn more about health and wellness and share as much information as possible with both of my parents. 
            </p>
          </div>
          <img className="no-mobile" src="/assets/images/journey-2.1.jpg"></img>
        </section>

        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <img className="no-mobile" src="/assets/images/journey-3.1.jpg"></img>
          <div className="bordered">
            <p>
              I wish I could say things were smooth sailing from here, but that wasn’t exactly the case. Eventually, I began feeling unwell and spent the next few years experiencing a full range of strange symptoms — including chronic pelvic pain (as a result of the salpingo-oophorectomy), regular headaches and migraines with aura, social anxiety, digestive problems, concentration issues, and fatigue. My doctors blamed everything on stress, telling me I needed to relax more, get plenty of sleep, and maybe consider taking medication for depression and anxiety.
            </p>
            <p>
              At that point, I’d already been doing all of those things — plus a lot more! And while I agreed with my doctors that chronic stress was definitely an issue, I knew something else had to be going on in addition to that. That’s when I was finally diagnosed with Hashimoto’s disease, after what seemed like hundreds of different labs and medical tests. Since then, I’ve been expanding my knowledge of health and wellness — not just for myself — but for others who are struggling just as I have been. Healing is still a work in progress, but that’s why I’m calling it my health and wellness journey! 
            </p>
          </div>
        </section>

        <section className="flex justify-content-center-space-between align-items-center halves m-y-3">
          <div className="bordered">
            <h1 className="m-y-2">Why Hire Me?</h1>
            <p>
              So, why should you hire me instead of a registered dietician, nutritionist, or journalist with years and years of professional experience in the health and wellness industry? Because I have the real-world experience and passion to share my love for wellness with others — and make a positive, long-lasting change in their lives. Plus, I’ve been writing professionally since 2012 and freelancing full-time since 2016. 
            </p>
            <p>
              I’m truly passionate about helping others to improve their physical, mental, and emotional well-being. <Link to="/contact">Contact me today and let’s work together to share our love and passion for health and wellness!</Link> 
            </p>
          </div>
          <img src="/assets/images/alex-beach-final.jpg"></img>
        </section>

      </React.Fragment>
    </Layout>
  )
}
